import axios from "axios";
import { adminAuthActions } from "../store/adminAuth";

const baseUrl = '/v1/';
const multiproviderBaseUrl = '/v2/';
const errorHandler = (err, dispatch) => {
    let errorMessage = {};
    switch (err.response && err.response.status) {
        case 401:
            errorMessage.severity = 'error';
            errorMessage.summary = err.response?.statusText ? err.response.statusText : "Error";
            errorMessage.errorMsg = err.response?.data?.errorMsg ? err.response.data.errorMsg : "Session Expired";
            dispatch(adminAuthActions.onLogout());
            break;
        default:
            errorMessage.severity = 'error';
            errorMessage.summary = err.response?.statusText ? err.response?.statusText : "Error";
            errorMessage.errorMsg = err.response.data?.errorMsg ? err.response.data.errorMsg : "Something went wrong, please try again";
            break;
    }
    return errorMessage;
}

export const login = (data, dispatch, callback) => {
    axios.post(baseUrl + 'login', data)
        .then(response => {
            const sessionId = response.headers['sessionid'];
            if (response && sessionId) {
                response.data.sessionId = sessionId;
                callback({ result: 'SUCCESS', data: response.data });
            } else {
                callback({ result: "FAILED", data: 'Failed' });
            }
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const getUsersList = ( obj, headers, dispatch, callback) => {
    const params = '?offset='+ obj.offset + '&limit=' + obj.limit+ '&orderBy=' + obj.sortField+'&direction=' + obj.sortOrder+ '&name=' + obj.name+'&email=' + obj.email+'&phone='+obj.phone+'&affiliateName='+obj.affiliateName;
    axios.get(baseUrl + 'users'+params, {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const getAffiliateUsersList = ( obj, headers, dispatch, callback) => {
    const params = '?offset='+ obj.offset + '&limit=' + obj.limit+ '&orderBy=' + obj.sortField+'&direction=' + obj.sortOrder +  '&name=' + obj.name+'&email=' + obj.email+'&phone='+obj.phone+'&status='+obj.status;
    axios.get(baseUrl + 'affiliateusers'+params, {headers : headers})
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data});
        }).catch(error => {
            const errorMessage = errorHandler(error,dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const getOrdersList = (obj, headers, dispatch, callback) => {
    let params = '?';
    if(obj.userId) {
        params+= 'userId='+obj.userId+'&';
    }
    params+= 'listingType='+obj.listingType+'&offset='+ obj.offset + '&limit=' + obj.limit + '&orderBy=' + obj.sortField+'&direction=' + obj.sortOrder+'&name=' + obj.name+'&affiliateName=' + obj.affiliateName+'&purchaseDateFrom=' + obj.purchaseDateFrom+'&purchaseDateTo=' + obj.purchaseDateTo+'&orderStatus=' + obj.orderStatus+'&paymentGateway=' + obj.paymentGateway+'&providerName=' + obj.providerName;
    axios.get(baseUrl + 'orders'+params, { headers: headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const getOrderDetails = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'orders', data, { headers: headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const getCountriesList = (headers, dispatch, callback) => {
    axios.get(baseUrl + 'countries', {headers : headers})
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data, ipcountry: response.headers['x-cf-ipcountry'] });
    }).catch(error => {
        const errorMessage = errorHandler(error,dispatch);
        callback({ result: 'FAILED', error: errorMessage });
    })
}

export const getCatalogsList = (req, headers, dispatch, callback) => {
    axios.get(multiproviderBaseUrl + 'prices?'+req, {headers : headers})
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
    }).catch(error => {
        const errorMessage = errorHandler(error,dispatch);
        callback({ result: 'FAILED', error: errorMessage });
    })
}

export const getPromoCodesList = (obj, headers, dispatch, callback) => {
    axios.get(baseUrl + 'promocodes?startTs=' + obj.startTs + '&endTs=' + obj.endTs + '&status=' + obj.status + '&type=' + obj.type, {headers : headers})
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
    }).catch(error => {
        const errorMessage = errorHandler(error,dispatch);
        callback({ result: 'FAILED', error: errorMessage });
    })
}

export const modifyPromoCode = (request, headers, dispatch, callback) => {
    const method = request.id ? "put" : "post"
        axios[method](baseUrl + 'promocode', request , { headers: headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const updatePrices = (data, headers, dispatch, callback) => {
    axios.put(baseUrl + 'prices', data, { headers: headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const getSiteMaintenance = (headers, dispatch, callback) => {
    axios.get(baseUrl + 'sitemaintenanceconfig', { headers: headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const updateSiteMaintenance = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'sitemaintenanceconfig', data, { headers: headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const importPriceCsv = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'uploadpricecsv', data, { headers: headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const getAssets = (headers, dispatch, callback) => {
    axios.get(baseUrl + 'assets', { headers: headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}

export const uploadAssetsApi = (data, headers, dispatch, callback) => {
    axios.post(baseUrl + 'uploadassets', data, { headers: headers })
      .then(response => {
        callback({ result: response.data === "success" ? 'SUCCESS' : "FAILED", data: response.data });
      })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
};

export const getLanguageTranslates = (lang,headers, dispatch, callback) => {
    axios.get(baseUrl + 'alltranslations', { 
        params: { language: lang }, 
        headers: headers 
    })    
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
    })
    .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
    })
}

export const uploadTranslations = (lang, payload, headers, dispatch, callback) => {
    const updatedHeaders = {
        ...headers,
        'language': lang,
      };
    axios
      .post(baseUrl + 'translate' ,  payload , { headers: updatedHeaders })
      .then((response) => {
        callback({ result: 'SUCCESS', data: response.data });
      })
      .catch((error) => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
  };
  export const getTabs = (dispatch, headers, ids, period, callback) => {
    axios
      .get(baseUrl + "monthlystats", {
        headers: headers,
        params: {
          ids: ids,
          period: period,
        },
      })
      .then((response) => {
        callback({ result: "SUCCESS", data: response.data });
      })
      .catch((error) => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: "FAILED", error: errorMessage });
      });
  };
  export const getGraphs = (dispatch, headers, id, period, callback) => {
    axios
      .get(baseUrl + "monthgraph", {
        headers: headers,
        params: {
          id: id,
          period: period,
        },
      })
      .then((response) => {
        callback({ result: "SUCCESS", data: response.data });
      })
      .catch((error) => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: "FAILED", error: errorMessage });
      });
  };
  
  export const getMonthGraphs = (dispatch, headers, period, callback) => {
    axios
      .get(baseUrl + "monthgraph/topbundlesmonth", {
        headers: headers,
        params: {
          period: period,
        },
      })
      .then((response) => {
        callback({ result: "SUCCESS", data: response.data });
      })
      .catch((error) => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: "FAILED", error: errorMessage });
      });
  };  

  export const getPriceFilters = (headers, dispatch, callback) => {
    axios.get(multiproviderBaseUrl + 'prices/filters', { 
        headers: headers 
    })    
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
    })
    .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
    })
}

export const getNotes = (dispatch, headers, id, callback) => {
    axios.get(baseUrl + `orders/${id}/notes`, { headers: headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data});
    })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
};

export const postNotes = (obj, dispatch, headers, id, callback) => {
    axios.post(baseUrl + `orders/${id}/notes`, obj, { headers: headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data});
    })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
};

export const networkInfo = (dispatch, headers, iccid, callback) => {
    axios.get(baseUrl + `esims/${iccid}/networkdetails`, { headers: headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data});
    })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
};

export const getBatchList = (req, headers, dispatch, callback) => {
    axios.get(baseUrl + 'promocodes/batch?' +req , {headers : headers})
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
    }).catch(error => {
        const errorMessage = errorHandler(error,dispatch);
        callback({ result: 'FAILED', error: errorMessage });
    })
}

export const getBatchInfo = (req, headers, dispatch, callback) => {
    axios.get(baseUrl + `promocodes/batch?batchId=${req.id}&status=${req.status}` , {headers : headers})
    .then(response => {
        callback({ result: 'SUCCESS', data: response.data });
    }).catch(error => {
        const errorMessage = errorHandler(error,dispatch);
        callback({ result: 'FAILED', error: errorMessage });
    })
}

export const createBatch = (request, headers, dispatch, callback) => {
        axios.post(baseUrl + 'promocodebatch', request , { headers: headers })
        .then(response => {
            callback({ result: 'SUCCESS', data: response.data });
        }).catch(error => {
            const errorMessage = errorHandler(error, dispatch);
            callback({ result: 'FAILED', error: errorMessage });
        })
}
export const fraudulentAPI = (obj, dispatch, headers, id, callback) => {
    axios.put(baseUrl + `orders/${id}/fraud`, obj, { headers: headers })
      .then(response => {
        callback({ result: 'SUCCESS', data: response.data});
    })
      .catch(error => {
        const errorMessage = errorHandler(error, dispatch);
        callback({ result: 'FAILED', error: errorMessage });
      });
};
